import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function Scene() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "2.5em" }}>
            <span className="purple"> Scene </span> Suggestions
        </h3>

        <h5> Joker and Harley Quinn. </h5>
        <p>
            Harley Quinn is a fan favorite and the #2 most popular search term on PornHub.
            I love to play the joker character and I want to creatively explore ways to give the fans what they want.
        </p>

        <h5> Princess Leia.  </h5>
        <p>
            Princess Leia in her iconic gold bikini is a sexual fantasy for hundreds of millions of men.
            I want to shoot parody adult content to play on this fantasy with a hardcore erotic twist.
        </p>

        <h5> Tantric Massage. </h5>
        <p>
            This is a favorite for the female models. 
            It explores the ancient art of tantric ritual and massage to enlighten the mind to the erotic ecstacy of tantric bliss.
            It allows me to completely serve the women, with my full focus on her sensation and pleasure.
        </p>

        <h5> Sex Therapy.</h5>
        <p> 
            This is a fan favorite, as well as one of my personal favorites.
            This scene could have a male or female therapist.
            It could also be personal, couples, or family therapy.
        </p>

        <h5> Harry Potter.</h5>
        <p> 
            This is one of my favorite book series. 
            I want to re-create the global classic with parody and an eroticism. 
            I really like the idea of creating Slytherin parody and sex content.
        </p>

        <h5> Sex Ed. </h5>
        <p>
            I want to do a series of how to videos for how to have good sex.
            For example, how to eat pussy, how to give a blowjob, how to have sex like a porn star.
            There is something extremely erousing about learning and seeing real sex that I am hoping to capture in this content.
        </p>

        <h5> Hotwife Breeding. </h5>
        <p> 
            This scene plays on the taboo of a married woman being impregnated by another man.
            Creampies are my favorite way to finish and I really enjoy the purely physical connection with hotwives.
            I like to do this in a classy and elgant way as opposed to obsenity or anything degrading.
        </p>

        <h5> Professor and Student. </h5>
        <p>
            This is a classic scene that plays on the fantasy of having sex with the hot professor.
            It can also play on the Professor's fantasy of having sex with a hot student.
        </p>

        <h5> Girlfriend and Girlfriend's Mom. </h5>
        <p>
            I had a huge crush on my first girlfriend's mom.
            I want to live out my own fantasy with FMF content where I am cheating with my girlfriend's mom and she catches us, then joins.
        </p>

        <h5> Classic Sex Tape. </h5>
        <p> 
            These types of scenes can be fun for the creators and are usually very low budget.
            The scene is as simple as setting up a tripod and having some fun.
            There is a large audience for this amateur style creation, that allows the creators to focus on having great sex.
        </p>

        <h5> Prostitute. </h5>
        <p> 
            One way this scene takes place is in a sketchy motel and plays on the fantasy of hiring a woman for cheap sex.
            It may also involve picking up a woman playing a prostitute off the street and then going to the motel.
            Another way this scene takes place is where I'm a acting as a prostitute serving a powerful business woman.
            This is a classier version, that would take place in an upscale hotel.
        </p>

        </p>

      </Container>
    </Container>
  );
}

export default Scene;
