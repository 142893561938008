import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

function About() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>

        <p style={{ color: "white" }}>
        <h3 style={{ fontSize: "2.5em" }}>
            <span className="purple"> About Andres' </span>  Content Creation
        </h3>

        <h4> Why I make adult content.</h4>
        <p>
            I make adult content because I genuinely enjoy exploring, experiencing, and creating a world of erotic fantasy.
            For me, I found that the best content creates an escape for the audience to a slightly different world.
            My goal is to create scenes that succeed for niche audiences at a global scale.
            I love meeting, connecting, and working with people in the adult industry.
            So, the most important thing to me is that the people I work with have a great experience.
        </p>

        <h4> How I make adult content.</h4>
        <p>
            I have an extremely rigorous professional and academic background. 
            So, I like to put in the work to make the end product great.
            My process is one of constant improvement, learning, and focus.
            
        </p>
        <p>
            My biggest turn on is giving pleasure and I like to make that my focus during shoots.
            So, I like to take scenes seriously, put in the effort, exchange ideas with collaborators, and go through multiple takes.
            The best way, in my opinion, to do great work is to work with the same people several times.
            This allows the actors to build rapport and confidence in eachother, to learn how to show eachother in the best light, and to create the hottest fantasy.
        </p>
        <p>
            I am TTS GSP tested and require everyone I work with to be as well.
            The main platforms I shoot for are PornHub, OnlyFans, Manyvids, and X. 
        </p>

        <h4> What kind of adult content I make.</h4>
        <p>
            I think the best content is created when both people are comfortable, creative, and having fun.
            Personally, I like to focus on niche areas, content that I connect with and that I enjoy making.
            I like to make hardcore adult content for the pornographic platforms, but I also like to make non-x rated content for other platforms.
            Ultimately, I want to create content that fans enjoy and that has viral potential.
        </p>

        </p>

      </Container>
    </Container>
  );
}

export default About;
